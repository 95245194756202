<template>
  <div>
    <!--    <Breadcrumb class="custom-breadcrumb" ref="breadcrumb" v-if="false">-->
    <!--      <BreadcrumbItem>课程管理</BreadcrumbItem>-->
    <!--      <BreadcrumbItem>课程列表</BreadcrumbItem>-->
    <!--      <BreadcrumbItem>新建课程</BreadcrumbItem>-->
    <!--    </Breadcrumb>-->
    <!--    <Card style="margin-top: 10px;" dis-hover>-->
    <Form ref="newCourseForm" :model="newCourseForm" :rules="ruleValidate" :label-width="132" class="yt-form yt-dialog-form">
      <FormItem label="课程名称" prop="name">
        <el-input placeholder="请输入名称" v-model="newCourseForm.name" />
        <div v-if="ruleValidate.name.error !== true" class="form-tip">
          不超过 80 个字
        </div>
      </FormItem>
      <FormItem label="课程封面" prop="cover">
        <Upload
          :headers="coverType === 0 ? {} : token"
          ref="upload"
          :show-upload-list="false"
          :data="uploadParams"
          :on-success="uploadCoverSuccess"
          :before-upload="beforeUpload"
          :on-progress="UploadImg"
          :on-exceeded-size="handleExceededSize"
          :format="['jpg', 'jpeg', 'png']"
          :max-size="10240"
          type="drag"
          :action="uploadUrl"
        >
          <Progress v-if="isUploading" :percent="percentage" hide-info />
          <img v-if="!isUploading && uploadSuccess" :src="newCourseForm.cover" alt="" />
          <div v-if="!isUploading && !uploadSuccess" class="upload">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
          </div>
        </Upload>
        <div class="form-tip" v-if="ruleValidate.cover.error !== true">
          支持 jpg/gif/png 格式，不超过 10 M
        </div>
      </FormItem>
      <FormItem label="选择岗位" prop="postIds" v-if="postId === ''">
        <el-cascader
          ref="cascader"
          style="width: 100%"
          size="small"
          :show-all-levels="false"
          :options="postData"
          v-model="newCourseForm.postIds"
          :props="{
            expandTrigger: 'hover',
            multiple: true,
            checkStrictly: true
          }"
          filterable
          clearable
        />
      </FormItem>
      <!--      <FormItem label="公开课程" prop="publicity">-->
      <!--        <RadioGroup v-model="newCourseForm.publicity">-->
      <!--          <Radio :label="0">是</Radio>-->
      <!--          <Radio :label="1">否</Radio>-->
      <!--        </RadioGroup>-->
      <!--      </FormItem>-->
      <FormItem label="课程简介" prop="description">
        <el-input type="textarea" placeholder="请输入课程简介" v-model="newCourseForm.description" />
        <div v-if="ruleValidate.description.error !== true" class="form-tip">
          不超过 600 个字
        </div>
      </FormItem>
    </Form>
    <div class="footer" slot="footer">
      <el-button @click="cancel" class="button">取消</el-button>
      <el-button type="primary" @click="nextStep" class="button" :loading="loading">确认</el-button>
    </div>
    <!--    </Card>-->
  </div>
</template>

<script>
import courseApi from '@api/course'
import ossApi from '@api/oss'
import DateTimePicker from '../../../common/DateTimePicker'
import AddTeacher from '../../../common/AddTeacher'
import functionApi from '@api/functionApi'
import config from '../../../../config/config'

export default {
  name: 'NewCourse',
  components: { AddTeacher, DateTimePicker },
  props: {
    postId: [String, Number],
    itCategoryId: {
      type: Number,
      default: 0
    }
  },
  data() {
    const that = this
    return {
      token: {
        Authorization: 'Bearer ' + (localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')).access_token)
      },
      coverType: '',
      serveLoca: '',
      localCoverUrl: '',
      postData: [],
      loading: false,
      percentage: 0,
      uploadSuccess: false,
      isUploading: false,
      uploadUrl: config.baseUrl + '/file/api/v1/upload/cover',
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      newCourseForm: {
        categoryId: 0,
        name: '',
        description: '',
        cover: '',
        publicity: 0,
        postIds: [this.postId],
        // category: 1,
        chapters: [],
        status: 1,
        students: []
      },
      ruleValidate: {
        postIds: {
          required: true,
          validator: (rule, value, callback) => {
            if (value.length === 0) {
              callback(new Error('请选择岗位'))
            } else {
              callback()
            }
          }
        },
        publicity: [{ required: true }],
        name: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.length === 0 || value.split(' ').join('').length === 0) {
              that.ruleValidate.name.error = true
              callback(new Error('课程名称不为空'))
            } else if (value.length > 80) {
              that.ruleValidate.name.error = true
              callback(new Error('名称不超过 80 字'))
            } else {
              that.ruleValidate.name.error = false
              callback()
            }
          }
        },
        cover: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.length === 0) {
              that.ruleValidate.cover.error = true
              callback(new Error('请选择课程封面'))
            } else {
              callback()
            }
          }
        },
        description: {
          error: false,
          validator: (rule, value, callback) => {
            if (value.length > 600) {
              that.ruleValidate.description.error = true
              callback(new Error('简介不超过 600 字'))
            } else {
              callback()
            }
          }
        }
      }
    }
  },
  watch: {
    postId(id) {
      this.newCourseForm.postIds = [id]
    }
  },
  created() {
    functionApi.getAllPostDataById(0).then(res => {
      let arr = JSON.stringify(res.res)
      this.postData = JSON.parse(
        arr
          .replace(/posts/g, 'children')
          .replace(/id/g, 'value')
          .replace(/name/g, 'label')
      )
    })
    ossApi.upType().then(type => {
      this.coverType = type.res
    })
  },
  methods: {
    cancel() {
      this.$refs.newCourseForm.resetFields()
      this.uploadSuccess = false
      this.newCourseForm.cover = ''
      this.$parent.$parent.cancelAdd()
    },
    beforeUpload(file) {
      // if (this.coverType === 0) {
      let type = file.name.slice(file.name.lastIndexOf('.'))
      let fileName = file.name.slice(0, -4)
      fileName = fileName.replace(/[ |~`!@#$%^&*()\-_+=\\\[\]{};:"',<.>\/?，。；：“”》《、]/g, '') + type
      let newFile = new File([file], fileName, { type: type })
      if (window.uploadUrl) {
        let formData = new FormData()
        formData.append('file', newFile)
        ossApi.upCover(formData).then(res => {
          this.serveLoca = res.data.res
          this.uploadCoverSuccess()
        })
      } else {
        const payload = {
          courseId: new Date().valueOf(),
          name: newFile.name
        }
        ossApi.getCourseCoverToken(payload).then(res => {
          const data = res.res
          this.uploadUrl = `https://${data.host}`
          this.uploadParams.key = data.dir
          this.uploadParams.OSSAccessKeyId = data.accessId
          this.uploadParams.policy = data.policy
          this.uploadParams.Signature = data.signature
          let formData = new FormData()
          formData.append('key', this.uploadParams.key)
          formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
          formData.append('policy', this.uploadParams.policy)
          formData.append('Signature', this.uploadParams.Signature)
          formData.append('file', newFile)
          fetch(`https://${data.host}`, {
            method: 'POST',
            body: formData
          }).then(() => {
            this.uploadCoverSuccess()
          })
        })
      }
      // }
      return false
    },
    UploadImg(event, file) {
      this.isUploading = true
      this.percentage = file.percentage
    },
    uploadCoverSuccess(res) {
      this.uploadSuccess = true
      this.isUploading = false
      if (window.uploadUrl) {
        this.newCourseForm.cover = this.serveLoca
      } else {
        this.newCourseForm.cover = `${this.uploadUrl}/${this.uploadParams.key}`
      }
    },
    handleExceededSize() {
      this.$message.error('图片大小不超过 10M')
    },
    nextStep() {
      this.$refs['newCourseForm'].validate(valid => {
        if (valid) {
          this.loading = true
          if (this.postId === '') {
            this.newCourseForm.postIds = this.$refs.cascader.getCheckedNodes().map(item => {
              return item.value
            })
          }
          this.newCourseForm.categoryId = this.itCategoryId
          courseApi
            .addCourses(this.newCourseForm)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('创建成功')
                // this.$parent.$parent.fetchCourse(true)
                this.cancel()
                this.$emit('on-ok')
              } else {
                this.loading = false
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.new-course-container {
  height: 100%;
  overflow: auto;
  text-align: center;
  background: white;
}
div ::v-deep .ivu-upload {
  width: 120px;
  height: 120px;
  img {
    width: 120px;
    height: 120px;
  }
}
.upload {
  width: 124px;
  height: 90px;
  font-size: 60px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #b8c2d1;
  }
}
</style>
