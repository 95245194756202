<template>
  <Row>
    <Col :span="dateSize">
      <DatePicker
        type="date"
        placeholder="日期"
        :style="{ width: dateSizePixel + 'px' }"
        :options="{ disabledDate }"
        @on-change="chooseDate"
        :editable="false"
        v-model="datePicker"
      >
      </DatePicker>
    </Col>
    <Col :span="timeSize">
      <TimePicker
        type="time"
        format="HH:mm"
        placeholder="时间"
        @on-change="chooseHour"
        style="margin-left: 5px"
        :style="{ width: timeSizePixel + 'px' }"
        hide-disabled-options
        :disabled-hours="disabledHours"
        :disabled-minutes="disabledMinutes"
        :disabled="disableTimePicker"
        :editable="false"
        :value="timePicker"
      >
      </TimePicker>
    </Col>
  </Row>
</template>

<script>
export default {
  name: 'DateTimePicker',
  props: {
    dateWidth: {
      type: Number,
      default: 12
    },
    timeWidth: {
      type: Number,
      default: 12
    },
    dateWidthPixel: {
      type: Number
    },
    timeWidthPixel: {
      type: Number
    },
    value: {
      type: [Date, String],
      default: ''
    }
  },
  data() {
    return {
      dateSize: this.dateWidth,
      timeSize: this.timeWidth,
      dateSizePixel: this.dateWidthPixel === undefined ? '' : this.dateWidthPixel,
      timeSizePixel: this.timeWidthPixel === undefined ? '' : this.timeWidthPixel,
      selectDate: '',
      selectDateEnd: '',
      thisDay: '',
      thisHour: '',
      thisHourEnd: '',
      datePicker: this.value,
      timePicker: this.value,
      disableTimePicker: !this.value
    }
  },
  mounted() {
    let date = new Date()
    let seperator = '-'
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let strDate = date.getDate()
    if (month >= 1 && month <= 9) {
      month = '0' + month
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = '0' + strDate
    }
    this.thisDay = year + seperator + month + seperator + strDate
  },
  computed: {
    disabledHours() {
      let hours = []
      if (this.selectDate === this.thisDay) {
        let hour = new Date().getHours()
        for (let i = 0; i < hour; i++) {
          hours.push(i)
        }
      }
      return hours
    },
    disabledMinutes() {
      let hour = new Date().getHours()
      let minutes = []
      if (this.thisHour === hour && this.selectDate === this.thisDay) {
        let minute = new Date().getMinutes()
        for (let i = 0; i < minute; i++) {
          minutes.push(i)
        }
      }
      return minutes
    }
  },
  methods: {
    disabledDate(date) {
      let d = new Date()
      d.setDate(d.getDate() - 1)
      return date < d
    },
    chooseDate(date) {
      this.selectDate = date
      let day = new Date()
      let hour = day.getHours()
      let minute = day.getMinutes() + 6
      if (minute >= 60) {
        minute = minute - 60
        hour = hour + 1
      }
      let time = hour + ':' + minute
      if (date === '') {
        this.timePicker = ''
        this.disableTimePicker = true
        this.$emit('input', '')
      } else if (date === this.thisDay) {
        this.timePicker = time
        this.thisHour = hour
        this.emitData()
        this.disableTimePicker = false
      } else {
        this.timePicker = '00:00'
        this.emitData()
        this.disableTimePicker = false
      }
    },
    chooseHour(hour) {
      this.thisHour = parseInt(hour.substring(0, 2))
      this.timePicker = hour
      this.emitData()
    },
    emitData() {
      this.$emit('input', _setDateAndTime(this.datePicker, this.timePicker))
    }
  },
  watch: {
    value(val) {
      this.datePicker = val
      this.timePicker = val
    }
  }
}
const _setDateAndTime = function(date, time) {
  if (date == null || !(date instanceof Date) || time == null || typeof time !== 'string') return null
  let origin = new Date()
  origin.setHours(Number(time.substring(0, 2)))
  origin.setMinutes(Number(time.substring(3, 5)))
  origin.setSeconds(0)
  origin.setFullYear(date.getFullYear())
  origin.setMonth(date.getMonth())
  origin.setDate(date.getDate())
  return origin
}
</script>

<style scoped></style>
