<template>
  <div>
    <div style="display: flex">
      <Select v-model="teacher" :multiple="multiple" @on-change="emitData" filterable>
        <Option v-for="teacher in teacherList" :key="teacher.teacherId" :value="teacher.teacherId">{{ teacher.teacherName }}</Option>
      </Select>
      <Button size="small" style="margin-left: 10px;height: 32px" @click="visible = true">新增讲师</Button>
    </div>
    <Modal v-model="visible" width="484" title="新增讲师" footer-hide>
      <Form ref="newTeacher" :label-width="80" :model="newTeacher" :rules="ruleValidate">
        <FormItem label="讲师姓名" prop="name">
          <Input v-model="newTeacher.name" placeholder="请输入讲师姓名"></Input>
        </FormItem>
        <FormItem label="讲师简介" prop="brief">
          <Input v-model="newTeacher.brief" placeholder="请输入讲师简介" type="textarea"></Input>
        </FormItem>
      </Form>
      <div style="text-align: center">
        <Button type="text" @click="visible = false">取消</Button>
        <Button @click="addTeacher" style="margin-left: 10px" type="primary" :loading="loading">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import courseApi from '@api/course'
import teacherMixin from '../mixins/teacherMixin'

export default {
  name: 'addTeacher',
  mixins: [teacherMixin],
  props: {
    isMultiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, String, Number],
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      multiple: this.isMultiple,
      teacher: this.value,
      newTeacher: {
        name: '',
        brief: ''
      },
      ruleValidate: {
        name: {
          required: true,
          message: '请输入讲师姓名'
        },
        brief: {
          max: 600,
          message: '讲师简介不超过 600 个字'
        }
      }
    }
  },
  methods: {
    emitData(value) {
      this.$emit('input', value)
    },
    addTeacher() {
      this.$refs['newTeacher'].validate(valid => {
        if (!valid) {
          this.$message.error('请填写必填项!')
        } else {
          this.loading = true
          const payload = {
            name: this.newTeacher.name,
            brief: this.newTeacher.brief
          }
          courseApi
            .addTeacher(payload)
            .then(() => {
              this.$message.success('添加成功')
              this.visible = false
              this.$refs['newTeacher'].resetFields()
              this.$store.dispatch('teacher/getTeacherList')
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  },
  watch: {
    value(val) {
      this.teacher = val
    }
  }
}
</script>

<style scoped lang="less">
@import '../../theme/variables';
</style>
